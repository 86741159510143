<template>
  <v-container class="login-container" fluid="fluid" fill-height="fill-height">
    <v-layout
      class="login-container"
      justify-center="justify-center"
      align-center="align-center"
    >
      <v-flex xs12="xs12" sm8="sm8" md4="md4">
        <v-card
          class="mx-auto px-1 elevation-4"
          :disabled="loading"
          :loading="loading"
          max-width="400"
          min-height="210"
        >
          <template v-if="screen === 'login'">
            <v-card-title class="font-weight-light">Time4Pet</v-card-title>
            <v-card-text>
              <v-text-field
                :error-messages="emailError"
                @focus="emailError = null"
                @keypress.enter="onSubmit()"
                label="Entre com seu e-mail"
                ref="email"
                type="email"
                v-if="visibleIf === 'login'"
                v-model="email"
              ></v-text-field
              ><span
                class="subtitle-1"
                color="success"
                v-if="visibleIf === 'sent'"
                >{{ sentMsg }}</span
              >
              <v-card-text
                class="body-1 text-center font-weight-black"
                v-if="visibleIf === 'sent'"
                >{{ waitingMsg }}</v-card-text
              >
            </v-card-text>
            <v-card-actions
              class="card-btn-bottom"
              v-if="visibleIf === 'login'"
            >
              <v-spacer></v-spacer>
              <v-btn @click="onSubmit" color="accent">Enviar</v-btn>
            </v-card-actions>
          </template>
          <template v-else>
            <v-card-title class="font-weight-light">Time4Pet</v-card-title>
            <v-spacer></v-spacer>
            <v-card-text class="subtitle-1 text-center">{{
              message
            }}</v-card-text>
            <v-card-text class="body-2 text-center" v-if="redirect">{{
              redirect
            }}</v-card-text>
          </template>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Events from "../../plugins/events";
import { auth, getMessageError } from "../../plugins/google/firebase";
const API_URL =
  process.env.NODE_ENV !== "production"
    ? process.env.VUE_APP_API_LOCAL
    : process.env.VUE_APP_API_URL;

export default {
  name: "email-link",
  data: () => ({
    loading: false,
    email: "",
    emailError: null,
    message: "Verificando login...",
    redirect: null,
    screen: "verif",
    sentMsg:
      "Enviamos um link para autenticação no seu e-mail, acesse e confirme",
    visibleIf: "login",
    waitingMsg: "Aguardando confirmação",
  }),
  mounted() {
    if (window.location.href.includes("apiKey")) {
      this.validateLogin();
      return;
    }

    this.screen = "login";
    Events.$on("authSuccessful", this.onAuthSuccessful);

    if (this.$store.state.session) {
      this.$router.replace("/");
      return;
    }
  },
  beforeDestroy() {
    Events.$off("authSuccessful", this.onAuthSuccessful);
  },
  methods: {
    onAuthSuccessful() {
      window.location.replace(`${API_URL}`);
    },
    onSubmit() {
      this.$refs.email.blur();

      if (!this.$utils.validateEmail(this.email)) {
        this.emailError = "Informe um e-mail válido";
        return;
      }

      this.loading = true;
      const actionCodeSettings = {
          url: `${API_URL}/#/email-link`,
          handleCodeInApp: true,
        },
        email = this.email.trim();

      auth
        .sendSignInLinkToEmail(email, actionCodeSettings)
        .then(() => {
          window.localStorage.setItem("emailForSignIn", email);
          this.visibleIf = "sent";
        })
        .catch((error) => {
          this.$messages.error({ text: getMessageError(error) });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validateLogin() {
      if (this.$store.state.session) {
        this.$router.replace("/");
        return;
      }

      try {
        if (auth.isSignInWithEmailLink(window.location.href)) {
          let email = window.localStorage.getItem("emailForSignIn");

          if (!email) {
            email = window.prompt(
              "Por favor informe seu e-mail para confirmar",
            );
          }

          auth
            .signInWithEmailLink(email, window.location.href)
            .then((result) => {
              this.$store.commit("session", result.user);

              window.localStorage.removeItem("emailForSignIn");
              this.message = "Autenticação realizada com sucesso";
              this.redirect = "Você está sendo redirecionado...";
              setTimeout(() => this.onAuthSuccessful(), 1800);
            })
            .catch((error) => {
              this.message = getMessageError(error);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
          this.message = "Não foi possível confirmar seu login";
        }
      } catch (e) {
        this.loading = false;
        this.message = "Não foi possível confirmar seu login";
      }
    },
  },
};
</script>

<style>
.login-container {
  background-color: #6565bf !important;
}
.card-btn-bottom {
  position: absolute !important;
  bottom: 4px;
  right: 4px;
}
</style>
